import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CalendarView} from 'angular-calendar';

@Component({
  selector: 'app-calendar-headerr',
  templateUrl: './calendar-headerr.component.html',
  styleUrls: ['./calendar-headerr.component.css']
})
export class CalendarHeaderrComponent implements OnInit {
  @Input() view: CalendarView | 'month' | 'week' | 'day';

  @Input() viewDate: Date;

  @Input() locale: string = 'en';

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
