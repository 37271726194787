import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import * as moment from 'moment';
declare const $: any;
import swal from 'sweetalert2';
@Component({
  selector: 'app-manage-devices',
  templateUrl: './manage-devices.component.html',
  styleUrls: ['./manage-devices.component.css']
})
export class ManageDevicesComponent implements OnInit {
  deviceList: any[];
  companyList: any[];
  savingDevice: boolean;
  selectedDevice: any;
  deviceData: any;
  popup = {
    title: 'Create device',
    btn: 'CREATE'
  };
  public activeDevice = null;
  constructor(private userService: UserService, private notifyService: NotificationService) { }

  ngOnInit() {
    this.savingDevice = false;
    this.selectedDevice = null;
    this.deviceList = [];
    this.companyList = [];
    this.deviceData = {
      dbId: null,
      name: null,
      description: null,
      mac_id: null,
      ime_id: null
    };
    this.getDevices();
    this.getCompanies();
  }
  public getCompanies() {
    this.userService.userListCompanies().subscribe((response: IResponse) => {
        this.companyList = response.data;
      },
      error => {
        this.notifyService.error('No Company found!', error);
      });
  }
  public getDevices() {
    this.userService.adminListDevices().subscribe((response: IResponse) => {
        this.deviceList = response.data;
        this.deviceList.forEach(device => {
          device.dateClean = moment(device.date).format('YYYY-MM-DD - hh:mm:ss - a');
        });
      },
      error => {
        this.notifyService.error('No Device found!', error);
      });
  }
  public createDeviceTrigger() {
    setTimeout(() => {
      this.popup = {
        title: 'Create device',
        btn: 'CREATE'
      };
      $('#createDevice').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }

  public closeModal() {
    this.savingDevice = false;
    $('#createDevice').modal('hide');
    $('#createDevice').hide();
    this.deviceData = {
      dbId: null,
      mac_id: null,
      name: null,
      description: null,
      ime_id: null
    };
    this.activeDevice = null;
  }
  public validateAndCreate() {
    this.savingDevice = true;
    if (this.activeDevice) {
      if (!this.deviceData.dbId || !this.deviceData.ime_id || !this.deviceData.mac_id)  {
        return this.notifyService.error('Fill the form before saving');
      }
      this.userService.updateDevice(this.deviceData, this.activeDevice._id).subscribe((response: IResponse) => {
        this.notifyService.success(response.msg);
        this.getDevices();
        this.getCompanies();
        this.closeModal();
      }, error => {
        this.savingDevice = false;
        this.notifyService.error(error.msg || 'Cannot update device');
      });
    } else {
      for ( const [key, value] of Object.entries(this.deviceData)) {
        if (!this.deviceData[key]) {
          this.savingDevice = false;
          this.notifyService.error(`${key} cannot be empty`);
          return false;
        }
      }
      this.userService.createDevice(this.deviceData).subscribe((response: IResponse) => {
        this.notifyService.success(response.msg);
        this.getDevices();
        this.getCompanies();
        this.closeModal();
      }, error => {
        this.savingDevice = false;
        this.notifyService.error(error.msg || 'Cannot create device');
      });
    }
  }
  public openDeviceLocation(device) {
    this.selectedDevice = device;
    setTimeout(() => {
      $('#viewDeviceLocation').modal({show: true, backdrop: 'static', keyboard: false });
    }, 200);

  }
  public deleteDevice(device) {
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.userService.deleteDevice(device._id).subscribe((response: IResponse) => {
          this.notifyService.success(response.msg);
          this.getDevices();
        }, error => {
          this.notifyService.error('Unable to delete device!');
        });
      }
    });
  }
  public closeDeviceLocation() {
    this.selectedDevice = null;
  }

  public triggerEdit(device) {
    this.deviceData = this.activeDevice = JSON.parse(JSON.stringify(device));
    this.deviceData.dbId = this.deviceData.dbId._id;
    this.popup = {
      title: 'Update device',
      btn: 'SAVE'
    };
    $('#createDevice').modal({show: true, keyboard: false, backdrop: 'static'});

  }
}
