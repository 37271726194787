/**
 *
 * Created By Arokoyu Olalekan Ojo <olalekan.arokoyu@upperlink.ng> @ 30/05/2018
 *
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs-compat/Observable';
import {isNullOrUndefined} from 'util';
import {HttpClient, HttpHeaders, HttpResponse, HttpParams} from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import {DecryptDataService} from '../encryption/encrypt-data.service';

@Injectable()
export class RestfulHttpService {
  token: string;
  options: any;
  headers_: any = {};
  SYSTEM_KEY: string;

  constructor(private http: HttpClient) {
    this.token  = env.TOKEN;
    this.SYSTEM_KEY = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJtaXNzaW9uIjoiTEVWRUxfT05FIiwicm9sZSI6IkFETUlOIiwicGFzc3dvcmQiOm51bGwsImFjY2Vzc19zdGF0dXMiOiJBTExPV0VEIiwiX2lkIjoiNWJkZWVjNzY1YjI5NDkwNDUwOTA0YzdlIiwic3RhZmZfaWQiOiIyMzQiLCJmaXJzdF9uYW1lIjoiT2xhbGVrYW4iLCJsYXN0X25hbWUiOiJBcm9rb3l1IiwiZW1haWwiOiJhcm9rb3l1b2xhbGVrYW5AZ21haWwuY29tIiwiY3JlYXRlZEF0IjoiMjAxOC0xMS0wNFQxMjo1NjoyMy4zMjZaIiwidXBkYXRlZEF0IjoiMjAxOC0xMS0wNFQxMjo1NjoyMy4zMjZaIiwiX192IjowLCJzZWNyZXRUb2tlbiI6IkdlT09IaUhZfjdJeF9lN3pKVHhseiIsImFjY2Vzc0tleSI6IjEyMzg0LTA5ODc1cG9pdXl0eS05ODd0bzg5MHBvanJ0OS0yOTc2dHJlZDtzZGZndXl0cjA4ai05ODd0bzg5MHBvanJ0OS0ydHJlZDtzZGZndXl0cjA4ai05ODd0bzg5MHBvanJ0OS0ydHJlZDtzZGZndXl0cjA4ai1hcm9rb3l1b2xhbGVrYW4iLCJpYXQiOjE1NDIyNzc2OTcsImV4cCI6MTU0MjMyMDg5N30.rqXYoyMRWggS2RiIsyt_w2OSiL9SAVwRm8mAxHkkSl0`;
     }

  createAuthorizationHeader(endpoint: string) {
    if (!isNullOrUndefined(sessionStorage.getItem('access'))) {
      this.headers_ = new HttpHeaders({
        'Content-Type':  'application/json',
        'Cache-Control':  'max-age=0, no-cache, must-revalidate, proxy-revalidate, private',
        'Authorization': endpoint === 'https://api.biochoice.ng/api/v1/sync/users' ? this.SYSTEM_KEY :  sessionStorage.getItem('access'),
        'Authorization_super': endpoint.match(/super-admin\/process/) ? this.SYSTEM_KEY : ''
      });
    } else {
      this.headers_ = new HttpHeaders({
        'Content-Type':  'application/json',
        'Cache-Control':  'max-age=0, no-cache, must-revalidate, proxy-revalidate, private'
      });
    }
  }
  appendTarget(endpoint) { //  || endpoint.match(/super-admin\/process/)
    if (endpoint.match(/pre-login\/access-generator/)) {
      return endpoint;
    } else {
      const  decrypt = new DecryptDataService();
      if (sessionStorage.getItem('system-target')) {
        const targetSystem = decrypt.decryptString('system-target');
        if (endpoint.match(/\?/)) {
          return `${endpoint}&${targetSystem}`;
        } else {
          return `${endpoint}?${targetSystem}`;
        }
      } else {
        return endpoint;
      }
    }
  }
  /**
   * A Restful Http get request.
   * @param {string} endpoint
   * @param {HttpParams} parameters
   * @returns {Observable<HttpResponse>}
   */
  public get(endpoint: string, parameters?: HttpParams): Observable<any> {
    this.createAuthorizationHeader(endpoint);

    if (parameters) {
        this.options = { params: parameters, headers: this.headers_ };
      } else {
        this.options = { headers: this.headers_ };
      }
      endpoint = this.appendTarget(endpoint);
      return this.http.get(endpoint, this.options);
  }

  public get1(endpoint: string, parameters?: HttpParams): Observable<any> {
    this.createAuthorizationHeader(endpoint);

    if (parameters) {
        this.options = { params: parameters, headers: this.headers_ };
      } else {
        this.options = { headers: this.headers_ };
      }
      endpoint = this.appendTarget(endpoint);
      return this.http.get(endpoint, this.options);
  }


  /**
   * A Restful Http post request.
   * @param {string} endpoint
   * @param data
   * @returns {Observable<HttpResponse>}
   */
  public post(endpoint: string, data: any): Observable<any> {
    this.createAuthorizationHeader(endpoint);
    endpoint = this.appendTarget(endpoint);
    return this.http.post(endpoint, data, {  headers: this.headers_ });
  }


  /**
   * A Restful Http delete Request.
   * @param {string} endpoint
   * @param data
   * @returns {Observable<HttpResponse>}
   */
  public delete(endpoint: string , data: any): Observable<any> {
    this.createAuthorizationHeader(endpoint);
    const params = new HttpParams(data);
    endpoint = this.appendTarget(endpoint);
    return this.http.delete(endpoint, {headers: this.headers_, params: params});

  }


  /**
   * A Restful Http put request.
   * @param {string} endpoint
   * @param data
   * @returns {Observable<HttpResponse>}
   */
  public put(endpoint: string, data: any): Observable<any> {
    this.createAuthorizationHeader(endpoint);
    endpoint = this.appendTarget(endpoint);
    return this.http.put(endpoint, data, {  headers: this.headers_ });
  }


  /**
   * A Restful Http patch request
    * @param {string} endpoint
   * @param data
   * @returns {Observable<HttpResponse>}
   */
  public patch(endpoint: string, data: any): Observable<any> {
    this.createAuthorizationHeader(endpoint);
    endpoint = this.appendTarget(endpoint);
    return this.http.patch(endpoint, data, {  headers: this.headers_ });
  }
}
