/**
 * Created by Arokoyu Olalekan Ojo
 */

import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {Observable} from 'rxjs/Observable';
import {ApiService} from '../api/api.service.';
import {isNullOrUndefined} from 'util';
import { environment as env } from '../../../../environments/environment';
import {IResponse} from '../../interfaces/iresponse';

@Injectable()
export class UserService {

  constructor(private api: ApiService) {

  }

  userSignUp(user: User): Observable<IResponse> {
    return this.api.postRequest('auth', 'signup', user).map((res: IResponse) => {
      return res ;
    });
  }


  auth(credentials): Observable<IResponse> {
    return this.api.postRequest('auth', 'login' , credentials).map((res: IResponse)  => {
      return res ;
    });
  }

  public resetPassword(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'reset-password?biochoice_access_control=' + env.DATABASE_HEADER, data).map( (res: IResponse) => {
      return res;
    });
  }
  public forgotPassword(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'forgot-password?biochoice_access_control=' + env.DATABASE_HEADER, data).map( (res: IResponse) => {
      return res;
    });
  }

  public updatePassword(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'change-password', data).map( (res: IResponse) => {
      return res;
    });
  }
  supeAuth(credentials): Observable<IResponse> {
    return this.api.postRequest('auth', 'super-login?biochoice_access_control=' + env.DATABASE_HEADER ,
      credentials).map((res: IResponse)  => {
      return res ;
    });
  }
  getCompanies(): Observable<IResponse> {
    return this.api.getRequest('auth', 'companies/pre-login/access-generator?biochoice_access_control='
      + env.DATABASE_HEADER).map((res: IResponse)  => {
      return res ;
    });
  }
  createCompany(data: any): Observable<IResponse> {
    return this.api.postRequest('tenancy', 'super-admin/process?biochoice_access_control='
      + env.DATABASE_HEADER , data).map((res: IResponse)  => {
      return res ;
    });
  }
  updateCompany(data: any, id: string): Observable<IResponse> {
    return this.api.putRequest('tenancy', `super-admin/process/${id}?biochoice_access_control=${env.DATABASE_HEADER}` , data)
      .map((res: IResponse)  => {
      return res ;
    });
  }
  createDevice(data: any): Observable<IResponse> {
    return this.api.postRequest('tenancy', 'super-admin/process/devices?biochoice_access_control='
      + env.DATABASE_HEADER , data).map((res: IResponse)  => {
      return res ;
    });
  }
  getDevices(): Observable<IResponse> {
    return this.api.getRequest('users', 'devices/company/list').map((res: IResponse)  => {
      return res ;
    });
  }
  getUserDevicePermission(userId): Observable<IResponse> {
    return this.api.getRequest('device-users', userId).map((res: IResponse)  => {
      return res ;
    });
  }
  createUserDevicePermission(userId, data): Observable<IResponse> {
    return this.api.postRequest('device-users', userId, data).map((res: IResponse)  => {
      return res ;
    });
  }
  updateDevice(data: any, id: string): Observable<IResponse> {
    return this.api.putRequest('tenancy', `super-admin/process/devices/${id}?biochoice_access_control=${env.DATABASE_HEADER}` , data)
      .map((res: IResponse)  => {
      return res ;
    });
  }
  deleteDevice(id: string): Observable<IResponse> {
    return this.api.deleteRequest('tenancy', `super-admin/process/devices/${id}?biochoice_access_control=${env.DATABASE_HEADER}`)
      .map((res: IResponse)  => {
      return res ;
    });
  }
  userListCompanies(): Observable<IResponse> {
    return this.api.getRequest('tenancy', 'super-admin/process?biochoice_access_control='
      + env.DATABASE_HEADER ).map((res: IResponse)  => {
      return res ;
    });
  }
  adminListDevices(): Observable<IResponse> {
    return this.api.getRequest('tenancy', 'super-admin/process/devices?biochoice_access_control='
      + env.DATABASE_HEADER ).map((res: IResponse)  => {
      return res ;
    });
  }
  listDevices(): Observable<IResponse> {
    return this.api.getRequest('devices', null).map((res: IResponse)  => {
      return res ;
    });
  }

  logOut(): Observable<IResponse> {
    return this.api.deleteRequest('auth', 'logout').map((res: IResponse)  => {
      return res ;
    });
  }

  getUsers(filter): Observable<IResponse> {
    return this.api.getRequest('users', `?filter=${filter.filter}`).map((res: IResponse)  => {
      return res ;
    });

  }
  getUser(userId): Observable<IResponse> {
    return this.api.getRequest('users', userId + '?biochoice_access_control='
      + env.DATABASE_HEADER).map((res: IResponse)  => {
      return res ;
    });

  }

  getLogCount(): Observable<IResponse> {
    return this.api.getRequest('logs', 'count').map((res: IResponse)  => {
      return res ;
    });

  }

  getUserCount(): Observable<IResponse> {
    return this.api.getRequest('users', 'count').map((res: IResponse)  => {
      return res ;
    });

  }


  getGuestCount(): Observable<IResponse> {
    return this.api.getRequest('guest', 'count').map((res: IResponse)  => {
      return res ;
    });

  }

  getGuests(): Observable<IResponse> {
    return this.api.getRequest('guest', null).map((res: IResponse)  => {
      return res ;
    });

  }
  exportToCSV(target): Observable<IResponse> {
    switch (target) {
      case 'staffs': {
        return this.api.getRequest('users', 'export').map((res: IResponse)  => {
          return res ;
        });
      }
      default: {
        break;
      }
    }

  }

  getTimeLogs(): Observable<IResponse> {
    return this.api.getRequest('logs', null).map((res: IResponse)  => {
      return res ;
    });

  }
  exportToCSVSyncData(): Observable<IResponse> {
    return this.api.getRequest('sync', 'admin-personnel').map((res: IResponse)  => {
      return res ;
    });

  }
  timeLogFilter(filter): Observable<IResponse> {
    return this.api.postRequest('logs', 'filter', filter).map((res: IResponse)  => {
      return res ;
    });
  }
  timeLogFilterSingle(filter): Observable<IResponse> {
    return this.api.postRequest('logs', 'filter/single', filter).map((res: IResponse)  => {
      return res ;
    });
  }
  timeLogFilterFew(): Observable<IResponse> {
    return this.api.getRequest('logs', 'today/few').map((res: IResponse)  => {
      return res ;
    });
  }
  firstLogs(): Observable<IResponse> {
    return this.api.getRequest('logs', 'all-first-logs').map((res: IResponse)  => {
      return res ;
    });
  }
  getLogComment(id: string): Observable<IResponse> {
    return this.api.getRequest('comment', 'log/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  createComment(data): Observable<IResponse> {
    return this.api.postRequest('comment', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  firstLogsForMonth(data): Observable<IResponse> {
    return this.api.postRequest('logs', 'first-logs-for-month', data).map((res: IResponse)  => {
      return res ;
    });
  }
  getGenerator(data): Observable<IResponse> {
    return this.api.postRequest('attendance', 'month-gen/get', data).map((res: IResponse)  => {
      return res ;
    });
  }
  generateAttendance(data): Observable<IResponse> {
    return this.api.postRequest('attendance', 'monthly/post', data).map((res: IResponse)  => {
      return res ;
    });
  }
  generatedAttendance(data): Observable<IResponse> {
    return this.api.postRequest('attendance', 'monthly/get', data).map((res: IResponse)  => {
      return res ;
    });
  }
  generatedDailyAttendance(data): Observable<IResponse> {
    return this.api.postRequest('daily-attendance', 'monthly/get', data).map((res: IResponse)  => {
      return res ;
    });
  }
  generatedAttendanceByStaff(data): Observable<IResponse> {
    return this.api.postRequest('attendance', 'monthly/get/user', data).map((res: IResponse)  => {
      return res ;
    });
  }
  dailyAttendanceByStaff(data): Observable<IResponse> {
    return this.api.postRequest('daily-attendance', 'monthly/get/user', data).map((res: IResponse)  => {
      return res ;
    });
  }
  getAttendanceById(attendanceId): Observable<IResponse> {
    return this.api.getRequest('daily-attendance', attendanceId + '?biochoice_access_control='
      + env.DATABASE_HEADER).map((res: IResponse)  => {
      return res ;
    });
  }
  firstLogsForDayInMonth(data): Observable<IResponse> {
    return this.api.postRequest('logs', 'first-logs-for-day-in-month', data).map((res: IResponse)  => {
      return res ;
    });
  }
  freeLogs(): Observable<IResponse> {
    return this.api.getRequest('logs', 'free').map((res: IResponse)  => {
      return res ;
    });
  }
  timeLogFilterByRange(startDate, endDate): Observable<IResponse> {
    return this.api.getRequest('logs', `filter/range?startDate=${startDate}&endDate=${endDate}`).map((res: IResponse)  => {
      return res ;
    });

  }
  timeLogFilterWithUser(date, staffId): Observable<IResponse> {
    return this.api.getRequest('logs', `staff/${staffId}/filter/range?date=${date}`).map((res: IResponse)  => {
      return res ;
    });

  }
  timeLogFilterByRangeWithUser(startDate, endDate, staffId): Observable<IResponse> {
    return this.api.getRequest('logs', `staff/${staffId}/filter/range?startDate=${startDate}&endDate=${endDate}`).map((res: IResponse)  => {
      return res ;
    });

  }
  deleteStaff(id): Observable<IResponse> {
    return this.api.deleteRequest('users', id).map((res: IResponse)  => {
      return res ;
    });

  }
  deleteLog(id): Observable<IResponse> {
    return this.api.deleteRequest('logs', id).map((res: IResponse)  => {
      return res ;
    });

  }
  sendMail(data): Observable<IResponse> {
    return this.api.postRequest('users', 'send-mail', data).map((res: IResponse)  => {
      return res ;
    });

  }
  getHolidays(): Observable<IResponse> {
    return this.api.getRequest('holiday', null).map((res: IResponse)  => {
      return res ;
    });

  }
  createHoliday(holiday): Observable<IResponse> {
    return this.api.postRequest('holiday', null, holiday).map((res: IResponse)  => {
      return res ;
    });

  }
  deleteHoliday(holidayId): Observable<IResponse> {
    return this.api.deleteRequest('holiday', holidayId).map((res: IResponse)  => {
      return res ;
    });

  }

  saveMailCommenting(data): Observable<IResponse> {
    return this.api.postRequest('daily-attendance', 'save-mail-comment?biochoice_access_control='
      + env.DATABASE_HEADER, data).map((res: IResponse)  => {
      return res ;
    });
  }
  setApprovedAmount(attendanceId, data): Observable<IResponse> {
    return this.api.putRequest('attendance', 'set-approved-amount/' + attendanceId, data).map((res: IResponse)  => {
      return res ;
    });

  }
  createOrUpdateStaff(data, isUpdateProcess): Observable<IResponse> {
    if (!isUpdateProcess) {
      return this.api.postRequest('users', null,  data ).map((res: IResponse) => {
        return res;
      });
    } else {
      return this.api.putRequest('users', data._id, data).map((res: IResponse) => {
        return res;
      });
    }

  }

}
