import {AfterViewInit, Component,  OnChanges, OnInit} from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import swal from 'sweetalert2';
declare const $: any;

@Component({
  selector: 'app-view-charges',
  templateUrl: './view-charges.component.html',
  styleUrls: ['./view-charges.component.css']
})
export class ViewChargesComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(private userService: UserService, private notifyService: NotificationService) { }
  public timeList: any[] = [];
  public loadingLogs: boolean;
  public showDays: number;
  public loadingLogs2: boolean;
  public loadingLogs3: boolean;
  public dailyFilter: boolean;
  public isFewLogs: boolean;
  public filterParams: any;
  public filterMode: string;
  public loadingFirstLogs: boolean;
  public fewLogs: any[] = [];
  public firstLogs: any[] = [];
  public days: any[] = [];
  ngOnInit() {
    this.showDays = 0;
    this.days = new Array(31).fill(0, 0, 30);
    this.filterMode = '';
    setTimeout(() => {
      // this.filterMode = 'Daily Basics';
      $('.selectpicker').selectpicker();
    }, 20);
    this.loadingLogs = true;
    this.loadingLogs2 = false;
    this.dailyFilter = false;
    this.filterParams = {
      fromDate: null,
      singleDate: null,
      toDate: null,
      staff_id: null
    };
    this.loadingLogs = true;
    this.loadingFirstLogs = false;
    // this.getLastFewLogs();
    this.getFirstLogs();

  }
  ngOnChanges() {

  }
  ngAfterViewInit() {
    // $('#datetimepicker1').datetimepicker();

  }
  public getTimeLogs() {
    this.userService.getTimeLogs().subscribe((response: IResponse) => {
        this.timeList = response.data;
        },
      error => {
        this.notifyService.error('No TimeLog found!', error);
      });
  }
  public deleteLog(log) {
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.userService.deleteLog(log._id).subscribe((response: IResponse) => {
          this.notifyService.success(response.msg);
          if (this.filterParams.staff_id || this.filterParams.toDate || this.filterParams.toDate) {
            this.filterLogs();
          } else {
            this.getLastFewLogs();
          }
        }, error => {
          this.notifyService.error('Unable to delete log!');
        });
      }
    });
  }
  public getUserLogs(staffId) {
    this.loadingLogs = true;
    this.filterParams = {
      fromDate: null,
      toDate: null,
      staff_id: staffId
    };
    this.filterLogs();
  }
  public filterLogs() {
    this.loadingLogs2 = true;
    this.updateField('fromDate');
    this.updateField('toDate');
    this.loadingLogs = true;
    this.userService.timeLogFilter(this.filterParams).subscribe((response: IResponse) => {
      this.fewLogs = response.data;
      this.loadingLogs2 = false;
      this.loadingLogs = false;
      if (this.fewLogs.length > 0 ) {
        setTimeout(() => {
          this.activateDataTable();
        }, 500);
      }
    }, error => {
      this.notifyService.error('No result available!', error);
      this.loadingLogs2 = false;
    });
  }
  public filterLogsByDay() {
    this.loadingLogs3 = true;
    this.updateField('singleDate');
    this.updateField('singleDate');
    this.fewLogs = [];
    this.loadingLogs = true;
    if (!this.filterParams.singleDate) {
      this.filterParams.singleDate = $('singleDate').val();
    }
    this.userService.timeLogFilterSingle(this.filterParams).subscribe((response: IResponse) => {
      this.fewLogs = response.data;
      this.loadingLogs3 = false;
      this.loadingLogs = false;
      if (this.fewLogs.length > 0 ) {
        setTimeout(() => {
          this.activateDataTable();
        }, 500);
      }
    }, error => {
      this.loadingLogs3 = false;
      this.notifyService.error('No result available!', error);
      this.loadingLogs2 = false;
    });
  }
  public updateField(id) {
    setTimeout(() => {
      const val = $('#' + id).val();
      this.filterParams[id] = val;
    }, 1000);
  }
  public activateDataTable() {
    $(('#dataTableTimeLog') as any).DataTable({
      'pagingType': 'full_numbers',
      'lengthMenu': [
        [30, 50, 100, 200, -1],
        [30, 50, 100, 200, 'All']
      ],
      destroy: true,
      responsive: true,
      'dom': 'lfrBtip',
      'buttons': [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }, {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }, {
          extend: 'print',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search Logs',
      }
    });
    // const table = $('#datatable').DataTable();
  }
  public activateFirstDataTable() {
    $(('#dataTableFirstTimeLog') as any).DataTable({
      'pagingType': 'full_numbers',
      'lengthMenu': [
        [30, 50, 100, 200, -1],
        [30, 50, 100, 200, 'All']
      ],
      destroy: true,
      responsive: true,
      'dom': 'lfrBtip',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search Logs',
      }
    });
    // const table = $('#datatable').DataTable();
   /* 'buttons': [
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }, {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }, {
        extend: 'print',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }
    ],*/
  }
  public getLogForMonth() {
    const filterInput = $('#getMonthly').val();
    this.loadingFirstLogs = true;
    this.userService.firstLogsForMonth({month: filterInput}).subscribe((response: IResponse) => {
      this.firstLogs = response.data;
      if (this.firstLogs.length > 0) {
        setTimeout(() => {
          this.activateFirstDataTable();
        }, 500);
      }
      this.loadingFirstLogs = false;
    }, error => {
      this.loadingFirstLogs = false;

    });
  }
  public getLogForDayInMonthApi() {
    const filterInput = $('#getDay').val();
    this.loadingFirstLogs = true;
    this.userService.firstLogsForDayInMonth({day: filterInput, month: this.showDays}).subscribe((response: IResponse) => {
      this.firstLogs = response.data;
      if (this.firstLogs.length > 0) {
        setTimeout(() => {
          this.activateFirstDataTable();
        }, 500);
      }
      this.loadingFirstLogs = false;
    }, error => {
      this.loadingFirstLogs = false;
    });
  }
  public getLogForDayInMonth () {
    this.showDays = $('#getDayInMonth').val();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 10);
  }
  public updateFilterView() {
    this.filterMode = $('#selectFilter').val();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
    if (this.filterMode === 'first' || this.filterMode === 'monthly') {
      return;
    } else {
      setTimeout(() => {
        (<any>$('.datetimepicker1')).datetimepicker({
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
          }
        });
        (<any>$('.datetimepicker2')).datetimepicker({
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
          }
        });
        (<any>$('.datepicker3')).datetimepicker({
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
          },
          format: 'YYYY-MM-DD'
        });
      }, 400);
    }
  }
  public getFirstLogs() {
    this.loadingFirstLogs = true;
    this.userService.firstLogs().subscribe((response: IResponse) => {
        this.firstLogs = response.data;
        this.loadingFirstLogs = false;
        if (this.firstLogs.length > 0) {
          setTimeout(() => {
            this.activateFirstDataTable();
          }, 500);
        }
      },
      error => {
        this.loadingFirstLogs = false;
        this.notifyService.error('No first log found', error);
      });
  }

  public getLastFewLogs() {
    this.isFewLogs = true;
    this.userService.timeLogFilterFew().subscribe((response: IResponse) => {
        this.fewLogs = response.data;
        this.loadingLogs = false;
        // this.activateDataTable();

        if (this.fewLogs.length > 0) {
          setTimeout(() => {
            this.activateDataTable();
          }, 500);
        }
      },
      error => {
        this.loadingLogs = false;
        this.notifyService.error('No Log found!', error);
      });
  }
}
