import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import {UserService} from '../../shared/services/userService/user.service';
import {NavigatorService} from '../../shared/services/navigatorService/navigator.service';
import {IResponse} from "../../shared/interfaces/iresponse";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public credentials = {
    email: null
  };
  public year = 2019;
  public userDetails: any;
  loaders = {
    loading: false
  };
  constructor(private notificationService: NotificationService,
              private userService: UserService,
              private navigatorService: NavigatorService) {}
  ngOnInit(): void {
    const date = new Date();
    this.year = date.getFullYear();
    this.userDetails = null;
    this.checkFullPageBackgroundImage();
    setTimeout(function() {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);
  }
  public checkFullPageBackgroundImage() {
    const page = $('.full-page');
    const image_src = page.data('image');
    if (image_src !== undefined) {
      const image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
      page.append(image_container);
    }
  }
  public forgotPassword() {
    this.loaders.loading = true;
    if (!this.credentials.email ) {
      this.notificationService.info('You have to provide email address to reset password!');
      this.loaders.loading = false;
      return;
    } else if (!this.credentials.email.match(this.EMAIL_VALIDATION) ) {
      this.notificationService.info('Not a valid email address!');
      this.loaders.loading = false;
      return;
    } else {
      this.userService.forgotPassword(this.credentials).subscribe((response: IResponse) => {
        this.loaders.loading = false;
        this.notificationService.success(response['msg'] || 'Password reset link sent to you at ' + this.credentials.email);
        this.navigatorService.navigateUrl('/');
      }, error => {
        this.notificationService.error(error.error.msg || 'Unable to reset password');
        this.loaders.loading = false;
      });
    }
  }
}
