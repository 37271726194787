import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {EncryptDataService} from '../../shared/services/encryption/encrypt-data.service';
import {NavigatorService} from '../../shared/services/navigatorService/navigator.service';
import {UserService} from '../../shared/services/userService/user.service';
import {IResponse} from '../../shared/interfaces/iresponse';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import * as jwt_decode from 'jwt-decode';

declare const $: any;

@Component({
  selector: 'app-login-office',
  templateUrl: './login-office.component.html',
  styleUrls: ['./login-office.component.css']
})
export class LoginOfficeComponent implements OnInit {
  public credentials = {
    email: null,
    password: null,
    secret: null
  };
  loaders: boolean;
  public year = 2019;
  public EMAIL_VALIDATION =  ENV.EMAIL_VALIDATION;
  constructor(private userService: UserService,
              private notify: NotificationService,
              private navigatorService: NavigatorService) {
    const date = new Date();
    this.year = date.getFullYear();
    this.loaders = false;
  }

  ngOnInit() {
    this.checkFullPageBackgroundImage();
    setTimeout(function() {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);
  }

  public checkFullPageBackgroundImage() {
    const page = $('.full-page');
    const image_src = page.data('image');
    if (image_src !== undefined) {
      const image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
      page.append(image_container);
    }
  }
  public loginProcess() {
    if (this.credentials.password && this.credentials.email && this.credentials.secret) {
      this.loaders = true;
      this.userService.supeAuth(this.credentials).subscribe((response: IResponse) => {
        const decode =  jwt_decode(response.data);
          if (decode.role.toLowerCase() === 'super') {
          this.navigatorService.navigateUrl(`/super/dash-board`);
        } else {
          this.navigatorService.navigateUrl(`/`);
        }
      }, error => {
        this.loaders = false;
        this.notify.error(error.msg || 'Invalid login details!');
      });
    } else {
      this.notify.error('No Email or Username provided!');
    }
  }

}
