import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../services/notificationServices/notification.service';
import {UserService} from '../../services/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';

declare const $: any;
import * as jwt_decode from 'jwt-decode';
import {AuthService} from '../../services/authService/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public password = {
    old_password: null,
    new_password: null,
    confirm_password: null,
    userId: null
  };
  public loading = false;
  public user  = null;
  constructor(private alertService: NotificationService, private userService: UserService, private authService: AuthService) { }

  ngOnInit() {
    this.user = jwt_decode(sessionStorage.getItem('access'));
    this.password.userId = this.user._id;
  }
  public updatePassword() {
    this.loading = true;
    if (!this.password.old_password) {
      this.alertService.error('You must enter your old password!');
      this.loading = false;
      return;
    } else if (!this.password.new_password) {
      this.alertService.error('You must enter new password!');
      this.loading = false;
      return;
    } else if (!this.password.confirm_password) {
      this.alertService.error('You must confirm new password!');
      this.loading = false;
      return;
    } else if (this.password.confirm_password !== this.password.new_password) {
      this.alertService.error('New password must match confirm password!');
      this.loading = false;
      return;
    } else if (this.password.new_password.length < 7) {
      this.alertService.error('New password too short!');
      this.loading = false;
      return;
    } else {
      this.userService.updatePassword(this.password).subscribe((res: IResponse) => {
        this.loading = false;
        this.password = {
          old_password: null,
          new_password: null,
          confirm_password: null,
          userId: this.user._id
        };
        this.alertService.success('Password updated successfully');
        this.authService.logUserOut();
      }, error => {
        this.loading = false;
        this.alertService.error(error.error.msg || 'Unable to update password');
      });
    }
  }
}
