import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigatorService} from '../../shared/services/navigatorService/navigator.service';

@Component({
  selector: 'app-admin-routes',
  templateUrl: './admin-routes.component.html',
  styleUrls: ['./admin-routes.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AdminRoutesComponent implements OnInit {

  constructor(private navigatorService: NavigatorService) {
    // this.navigatorService.navigateUrl('admin/dashboard');
  }

  ngOnInit() {}

}
