import {AfterViewInit, Component, ElementRef, OnChanges, OnInit} from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import swal from 'sweetalert2';
import * as jwt_decode from 'jwt-decode';

declare const $: any;

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit, AfterViewInit, OnChanges {
public loginUser = null;
  constructor(private userService: UserService, private notifyService: NotificationService,
              private elementRef: ElementRef) {
    this.loginUser = jwt_decode(sessionStorage.getItem('access'));
  }
  public timeList: any[] = [];
  public comments: any[] = [];
  public loadingLogs: boolean;
  public showDays: number;
  public loadingLogs2: boolean;
  public loadingLogs3: boolean;
  public dailyFilter: boolean;
  public monthFilter: boolean;
  public isFewLogs: boolean;
  public filterParams: any;
  public filterMode: string;
  public loadingFirstLogs: boolean;
  public availableMonths: any[] = [];
  public attendances: any[] = [];


  public commentFetched: boolean;
  public message: string;
  public currentLog: string;
  dataTableInstance: any;
  ngOnInit() {
    this.commentFetched = false;
    setTimeout(() => {
      // this.filterMode = 'Daily Basics';
      $('.selectpicker').selectpicker();
    }, 20);
    this.loadingLogs = true;
    this.loadingLogs2 = false;
    this.dailyFilter = false;
    this.monthFilter = false;
    this.loadingLogs = true;
    this.loadingFirstLogs = false;
    const date = new Date();
    let month = date.getMonth();
    let year = date.getFullYear();
    if (month === 0) {
      year--;
      month = 12;
    }
    this.getGeneratedAttendance({year, month});
  }
  ngOnChanges() {

  }
  ngAfterViewInit() {
    // $('#datetimepicker1').datetimepicker();

  }
  public updateField(id) {
    setTimeout(() => {
      const val = $('#' + id).val();
      this.filterParams[id] = val;
    }, 1000);
  }
  public activateFirstDataTable() {
    const DATATABLE = $(('#attendance_log') as any).DataTable({
      'pagingType': 'full_numbers',
      'lengthMenu': [
        [30, 50, 100, 200, -1],
        [30, 50, 100, 200, 'All']
      ],
      destroy: true,
      responsive: true,
      'dom': 'lfrBtip',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search Logs',
      },
      columnDefs: [ {
        targets: 'no-sort',
        orderable: false,
      } ],
    });
  }
  public saveApproveCharge(amount, log) {
      log.approvedCharge = Math.abs(amount);
      const charge = parseFloat(log.latenessCharge);
      if (log.approvedCharge > charge) {
        log.approvedCharge = charge;
        amount = log.approvedCharge;
      }
      this.userService.setApprovedAmount(log._id, {approvedCharge: Math.abs(parseFloat(amount))}).subscribe((res) => {

      }, error => {

      });
  }
  public updateFilterView() {
    this.monthFilter = false;
    this.filterMode = $('#selectFilter').val();
   this.getMyGenerators(this.filterMode);
  }
  fetchAttendance() {
    const month = $('#getMonth').val();
    this.getGeneratedAttendance({year: this.filterMode, month: month});
  }
  public getMyGenerators(year) {
    this.userService.getGenerator({year}).subscribe((response: IResponse) => {
      this.monthFilter = true;
      this.availableMonths = response.data.sort((a, b) =>  parseInt(a.month, 10) - parseInt(b.month, 10));

      this.availableMonths.forEach(record => {
        switch (parseInt(record.month, 10)) {
          case 1: {
            record.monthName = 'January';
            break;
          }
          case 2: {
            record.monthName = 'February';
            break;
          }
          case 3: {
            record.monthName = 'March';
            break;
          }
          case 4: {
            record.monthName = 'April';
            break;
          }
          case 5: {
            record.monthName = 'May';
            break;
          }
          case 6: {
            record.monthName = 'June';
            break;
          }
          case 7: {
            record.monthName = 'July';
            break;
          }
          case 8: {
            record.monthName = 'August';
            break;
          }
          case 9: {
            record.monthName = 'September';
            break;
          }
          case 10: {
            record.monthName = 'October';
            break;
          }
          case 11: {
            record.monthName = 'November';
            break;
          }
          case 12: {
            record.monthName = 'December';
            break;
          }
          default: {
            record.monthName = '--';
          }
        }
      });
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 20);
    }, error => {

    });
  }
  public getGeneratedAttendance(data) {
    this.loadingFirstLogs = true;
    this.userService.generatedAttendance(data).subscribe((response: IResponse) => {
        this.attendances = response.data;
        this.loadingFirstLogs = false;
        if (this.attendances.length > 0) {
          setTimeout(() => {
            this.activateFirstDataTable();
          }, 200);
        }
      },
      error => {
        this.loadingFirstLogs = false;
        this.notifyService.error('No attendance found for this month', error);
      });
  }



  public openCommentBox(log) {
    this.comments = null;
    this.currentLog = log._id;
    this.getComments(log._id, () => {
      this.commentFetched = true;
      setTimeout(() => {
        $('#leaveComment').modal({show: true, backdrop: 'static', keyboard: false });
      }, 10);
    });
  }
  public getComments(id, cb = null) {
    this.userService.getLogComment(id).subscribe((response: IResponse) => {
        this.comments = response.data;
        this.scrollIntoView();
        (cb) ? cb() : console.log();
      },
      () => {      });
  }
  public submitComment() {
    if (!this.message) {return; }
    this.userService.createComment({
      log_id: this.currentLog, comment: this.message,
      staff_id: this.loginUser.staff_id || null,
      user: this.loginUser._id}).subscribe((response: IResponse) => {
        this.getComments(this.currentLog, () => {
          this.message = null;
        });
      },
      () => {      });

  }
  public scrollIntoView() {
    setTimeout(() => {
      this.elementRef.nativeElement.querySelector('.commentSession').scrollIntoView({behavior: 'smooth'});
      this.elementRef.nativeElement.querySelector('.commentSession').scrollTop = 0;
    }, 200);
  }
}
