import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/authService/auth.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private authService: AuthService, private navigatorService: NavigatorService) { }

  ngOnInit() {

  }

  public logOutUser() {
    if (this.authService.logOut()) {
      this.navigatorService.navigateUrl('/');
    }
  }
}
