import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs-compat/observable/throw';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/take';
import {RestfulHttpService} from '../httpService/service.service';
import {HttpClient, HttpParams} from '@angular/common/http';
// import * as jwt_decode from 'jwt-decode';

import { environment as env } from '../../../../environments/environment';
import {isNullOrUndefined} from 'util';
/**
 *
 * Service to handle all api calls to api backend
 */
@Injectable()
export class ApiService extends RestfulHttpService {
  constructor(http: HttpClient) {
    super(http);
  }
 // todo: Users need to have keys inserted into them
// origin_selector_data_set=op0j5t-0oiojnhh-cds345&biochoice_application_get_system_unit=po98jgj7io87-oi782456&client_company_biochoice_pointer=Upperlink Limited
  /**
   * Handler error types
   * @param error
   */

  errorHandler(error) {
    try {
      /*if (error.status === 401) {
        sessionStorage.clear();
        localStorage.clear();
        // window.location.href = '/';
        return throwError(error || {msg: 'Unknown error type occurred'});
      }*/
      return throwError(error || {msg: 'Unknown error type occurred'});
    } catch (error) {

    }
  }


  public decode(res, auth?: string) {
    const data = res.data;
    if (res && !isNullOrUndefined(res.data)) {
      if (auth && auth.includes('login')) {
        // const decode =  jwt_decode(res.data);
        sessionStorage.setItem('access', data);
        // res.data = decode;

      }
      return res;
    } else {
      return res;
    }
  }



  deleteRequest(api: string , method: string , data?: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method)) {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.delete(ENDPOINT, data).retryWhen(error => {
    return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }



  putRequest(api: string , method: string , data: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method)) {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.put(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });

  }



  patchRequest(api: string, method: string , data: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method)) {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.patch(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }



  getRequest(api: string , method: string , params?: HttpParams): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(method)) {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.get(ENDPOINT, params).retryWhen(error => {
      return error.mergeMap(err => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }


  postRequest(api: string, method: string , data: any): Observable<any> {
    let ENDPOINT;

    if (!isNullOrUndefined(method)) {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + method;
    } else {
       ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
      return super.post(ENDPOINT, data).retryWhen(error => {
        return error.mergeMap((err) => this.errorHandler(err))
          .delay(1000)
          .take(2);
      }).catch(this.errorHandler).map(res => this.decode(res, method));

    }
}
