import {AfterViewInit, Component, ElementRef, OnChanges, OnInit} from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import swal from 'sweetalert2';
import * as jwt_decode from 'jwt-decode';

declare const $: any;

@Component({
  selector: 'app-time-logs',
  templateUrl: './time-logs.component.html',
  styleUrls: ['./time-logs.component.css']
})
export class TimeLogsComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(private userService: UserService, private notifyService: NotificationService,
              private elementRef: ElementRef) { }
public timeList: any[] = [];
public comments: any[] = [];
  public loadingLogs: boolean;
  public loadingLogs2: boolean;
  public loadingLogs3: boolean;
  public dailyFilter: boolean;
  public isFewLogs: boolean;
  public commentFetched: boolean;
  public filterParams: any;
  public filterMode: string;
  public message: string;
  public currentLog: string;
  public user: any;
  public loadingFirstLogs: boolean;
public fewLogs: any[] = [];
public firstLogs: any[] = [];
  ngOnInit() {

    this.user = jwt_decode(sessionStorage.getItem('access'));
    this.commentFetched = false;
    this.filterMode = 'Daily Basics';
    setTimeout(() => {
      // this.filterMode = 'Daily Basics';
      $('.selectpicker').selectpicker();
    }, 20);
    this.loadingLogs = true;
    this.loadingLogs2 = false;
    this.dailyFilter = false;
    this.filterParams = {
      fromDate: null,
      singleDate: null,
      toDate: null,
      user_id: null
    };
    if (this.user.role.toLowerCase() !== 'admin') {
      this.filterParams.user_id = this.user._id;
    }
    this.loadingLogs = true;
    this.loadingFirstLogs = false;
  // this.getTimeLogs();
  this.getLastFewLogs();
  }
  ngOnChanges() {

  }
  ngAfterViewInit() {
    // $('#datetimepicker1').datetimepicker();

  }
  public getTimeLogs() {
    this.userService.getTimeLogs().subscribe((response: IResponse) => {
        this.timeList = response.data;
        },
      error => {
        this.notifyService.error('No TimeLog found!', error);
      });
  }
  public getLastFewLogs() {
    this.isFewLogs = true;
    this.userService.timeLogFilterFew().subscribe((response: IResponse) => {
        this.fewLogs = response.data;
        this.loadingLogs = false;
        // this.activateDataTable();

        if (this.fewLogs.length > 0) {
          setTimeout(() => {
            this.activateDataTable();
          }, 500);
        }
      },
      error => {
        this.loadingLogs = false;
        this.notifyService.error('No Log found!', error);
      });
  }
  public deleteLog(log) {
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.userService.deleteLog(log._id).subscribe((response: IResponse) => {
          this.notifyService.success(response.msg);
          if (this.filterParams.user_id || this.filterParams.toDate || this.filterParams.toDate) {
            this.filterLogs();
          } else {
            this.getLastFewLogs();
          }
        }, error => {
          this.notifyService.error('Unable to delete log!');
        });
      }
    });
  }
  public getUserLogs(userId) {
    this.loadingLogs = true;
    this.filterParams = {
      fromDate: null,
      toDate: null,
      user_id: userId
    };
    this.filterLogs();
  }
  public filterLogs() {
    this.loadingLogs2 = true;
    this.updateField('fromDate');
    this.updateField('toDate');
    this.loadingLogs = true;
    this.userService.timeLogFilter(this.filterParams).subscribe((response: IResponse) => {
      this.fewLogs = response.data;
      this.loadingLogs2 = false;
      this.loadingLogs = false;
      if (this.fewLogs.length > 0 ) {
        setTimeout(() => {
          this.activateDataTable();
        }, 500);
      }
    }, error => {
      this.notifyService.error('No result available!', error);
      this.loadingLogs2 = false;
    });
  }
  public filterLogsByDay() {
    if (this.user.role.toLowerCase() !== 'admin') {
      this.filterParams.user_id = this.user._id;
    }
    this.loadingLogs3 = true;
    this.updateField('singleDate');
    this.updateField('singleDate');
    this.fewLogs = [];
    this.loadingLogs = true;
    if (!this.filterParams.singleDate) {
      this.filterParams.singleDate = $('singleDate').val();
    }
    this.userService.timeLogFilterSingle(this.filterParams).subscribe((response: IResponse) => {
      this.fewLogs = response.data;
      this.loadingLogs3 = false;
      this.loadingLogs = false;
      if (this.fewLogs.length > 0 ) {
        setTimeout(() => {
          this.activateDataTable();
        }, 500);
      }
    }, error => {
      this.loadingLogs3 = false;
      this.notifyService.error('No result available!', error);
      this.loadingLogs2 = false;
    });
  }
  public updateField(id) {
    setTimeout(() => {
      const val = $('#' + id).val();
      this.filterParams[id] = val;
    }, 1000);
  }
  public activateDataTable() {
    $(('#dataTableTimeLog') as any).DataTable({
      'pagingType': 'full_numbers',
      'lengthMenu': [
        [30, 50, 100, 200, -1],
        [30, 50, 100, 200, 'All']
      ],
      destroy: true,
      responsive: true,
      'dom': 'lfrBtip',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search Logs',
      }
    });
    // const table = $('#datatable').DataTable();
   /* 'buttons': [
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }, {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }, {
        extend: 'print',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }
    ],*/
  }
  public activateFirstDataTable() {
    $(('#dataTableFirstTimeLog') as any).DataTable({
      'pagingType': 'full_numbers',
      'lengthMenu': [
        [30, 50, 100, 200, -1],
        [30, 50, 100, 200, 'All']
      ],
      destroy: true,
      responsive: true,
      'dom': 'lfrBtip',
      'buttons': [
        {
          extend: 'excelHtml5',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }, {
          extend: 'pdfHtml5',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }, {
          extend: 'print',
          exportOptions: {
            columns: [ 0, 1, 2, 3, 4 ]
          }
        }
      ],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search Logs',
      }
    });
    // const table = $('#datatable').DataTable();
  }
public updateFilterView() {
    this.filterMode = $('#selectFilter').val();
    if (this.filterMode === 'first') {
    this.getFirstLogs();
    } else if (this.filterMode === 'today') {
      this.getLastFewLogs();
    } else {
      setTimeout(() => {
        (<any>$('.datetimepicker1')).datetimepicker({
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
          }
        });
        (<any>$('.datetimepicker2')).datetimepicker({
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
          }
        });
        (<any>$('.datepicker3')).datetimepicker({
          icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
          },
          format: 'YYYY-MM-DD'
        });
      }, 400);
    }
}
  public getFirstLogs() {
    this.loadingFirstLogs = true;
    this.isFewLogs = false;
      this.userService.firstLogs().subscribe((response: IResponse) => {
        this.firstLogs = response.data;
        this.loadingFirstLogs = false;
          if (this.fewLogs.length > 0) {
            setTimeout(() => {
              this.activateFirstDataTable();
            }, 500);
          }
        },
      error => {
        this.getFirstLogs();
        this.loadingFirstLogs = false;
        this.notifyService.error('No first log found', error);
      });
  }
  public openCommentBox(log) {
    this.comments = null;
    this.currentLog = log._id;
    this.getComments(log._id, () => {
      this.commentFetched = true;
      setTimeout(() => {
        $('#leaveComment').modal({show: true, backdrop: 'static', keyboard: false });
      }, 10);
    });
  }
  public getComments(id, cb = null) {
    this.userService.getLogComment(id).subscribe((response: IResponse) => {
        this.comments = response.data;
        this.scrollIntoView();
        (cb) ? cb() : console.log();
      },
      () => {      });
  }
  public submitComment() {
  if (!this.message) {return; }
    this.userService.createComment({
      log_id: this.currentLog, comment: this.message,
      staff_id: this.user.staff_id,
      user: this.user._id}).subscribe((response: IResponse) => {
        this.getComments(this.currentLog, () => {
          this.message = null;
        });
      },
      () => {      });

  }
  public scrollIntoView() {
       setTimeout(() => {
      this.elementRef.nativeElement.querySelector('.commentSession').scrollIntoView({behavior: 'smooth'});
      this.elementRef.nativeElement.querySelector('.commentSession').scrollTop = 0;
    }, 200);
  }
}
