import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../authService/auth.service';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {CacheService} from '../cacheService/cache.service';
import {EventsService} from '../eventServices/event.service';
import * as JWT_DECODE from 'jwt-decode';

@Injectable()
export class GuardService implements CanActivate {
  url: string;
  constructor( private router: Router, private eventsService: EventsService,
               private authService: AuthService, private cache_: CacheService, private cacheService: CacheService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.url = state.url;
    return this.checkLogin(this.url);
  }

  checkLogin(url?: string): boolean {
    if (this.authService.loggedAdminIn()) { return true; }
   // this.authService.redirectUrl = url; // set url in authService here
    this.router.navigate([ '/' ]); // then ask user to login
    return false;
  }

  /**
   * Log out from system
   */
  logOut() {
   const location = this.cache_.getStorage('MemberLocationCode');
    this.cache_.clearStorage();
    this.cache_.clearSession();
    this.cache_.setStorage('MemberLocationCode', location);
     this.router.navigate([ '/' ]);
  }

}


@Injectable()
export class RoleService implements CanActivate {
  static getAuthUser() {
    const token = sessionStorage.getItem('access');
    const decodedToken = JWT_DECODE(token);
    return decodedToken;
  }
  static checkRole(role): boolean {
    const user = RoleService.getAuthUser();
    return user && user.role === role;
  }
  constructor( ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const role = route.data.roles;
    return RoleService.checkRole(role);
  }
}
