import {IRouting} from './shared/interfaces/irouting';
import {RouterModule, Routes} from '@angular/router';
import {AdminRoutesComponent} from './admin/admin-routes/admin-routes.component';
import {UserRoutesComponent} from './user/user-routes/user-routes.component';
import {LoginComponent} from './landing/login/login.component';
import {GuardService, RoleService} from './shared/services/gaurdService/guard.service';
import {LoginOfficeComponent} from './landing/login-office/login-office.component';
import {SuperAdminRoutesComponent} from './super-admin/super-admin-routes/super-admin-routes.component';
import {UserDashboardComponent} from './user/user-dashboard/user-dashboard.component';
import {AdminDashboardComponent} from './admin/admin-dashboard/admin-dashboard.component';
import {ManageUsersComponent} from './admin/manage-users/manage-users.component';
import {TimeLogsComponent} from './admin/time-logs/time-logs.component';
import {VisitorsComponent} from './admin/visitors/visitors.component';
import {ManageCompaniesComponent} from './super-admin/manage-companies/manage-companies.component';
import {ManageDevicesComponent} from './super-admin/manage-devices/manage-devices.component';
import {SuperDashboardComponent} from './super-admin/super-dashboard/super-dashboard.component';
import {ManageDeviceAdminComponent} from './admin/manage-device-admin/manage-device-admin.component';
import {ViewChargesComponent} from './admin/view-charges/view-charges.component';
import {AttendanceComponent} from './admin/attendance/attendance.component';
import {MyAttendanceComponent} from './user/attendance/attendance.component';
import {DailyAttendanceComponent} from './landing/daily-attendance/daily-attendance.component';
import {DailyLogAttendanceComponent} from './admin/daily-attendance/attendance.component';
import {RevokedUsersComponent} from './admin/revoked-users/revoked-users.component';
import {ForgotPasswordComponent} from './landing/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './landing/reset-password/reset-password.component';
import {ChangePasswordComponent} from "./shared/components/change-password/change-password.component";


const landingRoutes: Routes = [
  {path: '', component: LoginComponent, pathMatch: 'full'},
  {path: 'reset-password/:userId/:codeSecret', component: ResetPasswordComponent},

  {path: 'reset-password', component: ForgotPasswordComponent},
  {path: 'administrator', component: LoginOfficeComponent},

  {path: 'attend-to-daily-log/:userId/:userName/:token/:attendanceId', component: DailyAttendanceComponent},

  {path: 'user', loadChildren: './shared/modules/user/user.module#UserModule'},

  {path: 'admin', loadChildren: './shared/modules/admin/admin.module#AdminModule'},

  {path: 'super', loadChildren: './shared/modules/super-admin/super-admin.module#SuperAdminModule'},

  {path: '**', component: LoginComponent},
];

export const landingRouting: IRouting = {
  routes: RouterModule.forRoot(landingRoutes, {useHash: true}),
  components: [
    LoginComponent,
    LoginOfficeComponent,
    DailyAttendanceComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginOfficeComponent],
  entryComponent: [],
  providers: []
};


export const userRoutes: Routes = [
  {path: '', component: UserRoutesComponent, children: [
    {path: 'dashboard', component: UserDashboardComponent},
    {path: 'logs', component: TimeLogsComponent},
    {path: 'change-password', component: ChangePasswordComponent},
    {path: 'my-attendance', component: MyAttendanceComponent},
    {path: 'view-charge', component: ViewChargesComponent},

  ], canActivate: [GuardService]},
  {path: '**', component: UserRoutesComponent, canActivate: [GuardService]}
];

export const userRouting: IRouting = {
  routes: RouterModule.forChild(userRoutes),
  components: [
    UserRoutesComponent,
    UserDashboardComponent],
  entryComponent: [],
  providers: []
};

export const adminRoutes: Routes = [
  {path: '', component: AdminRoutesComponent, children: [
    {path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'dashboard', component: AdminDashboardComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'manage-users', component: ManageUsersComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'revoked-users', component: RevokedUsersComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'manage-logs', component: TimeLogsComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'view-charges', component: ViewChargesComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'attendance', component: AttendanceComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'change-password', component: ChangePasswordComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'daily-attendance', component: DailyLogAttendanceComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
    {path: 'visitors', component: VisitorsComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
      {path: 'my--attendance', component: MyAttendanceComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
      {path: 'my--logs', component: TimeLogsComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}},
      // tslint:disable-next-line:max-line-length
      {path: 'manage-devices-admin', component: ManageDeviceAdminComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'}}
  ]
  },
  {path: '**', component: AdminRoutesComponent, canActivate: [GuardService, RoleService], data: {roles: 'ADMIN'} }
];

export const adminRouting: IRouting = {
  routes: RouterModule.forChild(adminRoutes),
  components: [
    AdminRoutesComponent,
    AdminDashboardComponent,
    ManageDeviceAdminComponent,
    ManageUsersComponent,
    VisitorsComponent,
    RevokedUsersComponent,
    DailyLogAttendanceComponent,
    AttendanceComponent
  ],
    entryComponent: [],
    providers: []
};

export const superAdminRoutes: Routes = [
  {path: '', component: SuperAdminRoutesComponent, children: [
    {path: 'dash-board', component: SuperDashboardComponent,  canActivate: [RoleService], data: {roles: 'SUPER'}},
    {path: 'manage-companies', component: ManageCompaniesComponent,  canActivate: [RoleService], data: {roles: 'SUPER'}},
    {path: 'change-password', component: ChangePasswordComponent,  canActivate: [RoleService], data: {roles: 'SUPER'}},
    {path: 'manage-devices', component: ManageDevicesComponent,  canActivate: [RoleService], data: {roles: 'SUPER'}}
  ]},
  {path: '**', component: SuperAdminRoutesComponent,  canActivate: [RoleService], data: {roles: 'SUPER'} }
];

export const superAdminRouting: IRouting = {
  routes: RouterModule.forChild(superAdminRoutes),
  components: [
    SuperAdminRoutesComponent,
    ManageCompaniesComponent,
    SuperDashboardComponent,
    ManageDevicesComponent],
    entryComponent: [],
    providers: []
};

