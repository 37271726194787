import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
declare const $: any;
import * as jwt_decode from 'jwt-decode';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/userService/user.service';
import {NavigatorService} from "../../services/navigatorService/navigator.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  public user: any;

  static updateActiveBar(url) {
   setTimeout(() => {
     let oldUrl;
     oldUrl = 'dashboard';
     $('.nav-item').removeClass('active');
     $(`#${url}`).addClass('active');
     if ($(`#${url}`).hasClass('active')) {
       oldUrl = url;
     } else {
       $(`#${oldUrl}`).addClass('active');
     }
   }, 500);
  }

  constructor(private route: Router, private userService: UserService, private navigationService: NavigatorService) { }

  ngOnInit() {
    this.user = jwt_decode(sessionStorage.getItem('access'));
  }
  openPage(url) {
    this.navigationService.navigateUrl(`/${this.user.role.toLowerCase()}/${url}`);
  }
  ngAfterViewInit() {
    const routes = this.route.url.split('/');
    SidebarComponent.updateActiveBar(routes[routes.length - 1]);
    const element = document.querySelectorAll('.special-class');
    element.forEach((el) => {
      el.addEventListener('click', (e) => {
      $('.nav-item').removeClass('active');
      const id = el['id'];
      if (id.includes('#') || !id) {return false; }
      $(`#${id}`).addClass('active');

    });
    });
  }
}
