import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import {NavigatorService} from '../../shared/services/navigatorService/navigator.service';
import * as moment from 'moment';
@Component({
  selector: 'app-daily-attendance',
  templateUrl: './daily-attendance.component.html',
  styleUrls: ['./daily-attendance.component.css']
})
export class DailyAttendanceComponent implements OnInit, AfterViewInit {
  public year = 2019;
  public comment: string = null;
  public loader: boolean;
  public commentable: boolean;
  public mailData = {
    userId: null,
    token: null,
    attendanceId: null,
    userName: null,
    comment: null
  };
  public userData = {user: null, attendance: null, comments: [] };
  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private alertService: NotificationService,
              private navigateService: NavigatorService) {
    const date = new Date();
    this.year = date.getFullYear();
    this.loader = false;
    this.commentable = false;


    this.mailData.userId = this.route.snapshot.paramMap.get('userId');
    this.mailData.token = this.route.snapshot.paramMap.get('token');
    this.mailData.attendanceId = this.route.snapshot.paramMap.get('attendanceId');
    this.mailData.userName = this.route.snapshot.paramMap.get('userName');
    this.getUser();
    this.getAttendance();
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    setTimeout(function() {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);
  }
public getUser() {
    this.userService.getUser(this.mailData.userId).subscribe((res) => {
      this.userData.user = res.data;
    }, error => {
      this.alertService.error('Error: Unable to get user details, redirecting in 60 seconds!');
      setTimeout(() => {
        this.navigateService.navigateUrl('/');
      }, 60000);
    });
}
public getAttendance() {
    this.userService.getAttendanceById(this.mailData.attendanceId).subscribe((res) => {
      this.userData.attendance = res.data.attendance;
      this.userData.comments = res.data.comments;
      this.userData.attendance.timeInFormatted = moment(this.userData.attendance.attendanceTime).format(':mm:ss:A');
      if (this.userData.comments.length >= 2) {
        this.commentable = false;
        this.alertService.info('Info: You already drop a comment here, redirecting in 60 seconds!');
        setTimeout(() => {
          this.navigateService.navigateUrl('/');
        }, 60000);
      } else {
        this.commentable = true;
      }
    }, error => {
      this.alertService.error('Error: Unable to get attendance details, redirecting in 60 seconds!');
      setTimeout(() => {
        this.navigateService.navigateUrl('/');
      }, 60000);
    });
}
public submitComment() {
    if (!this.comment) {
      return this.alertService.error('Enter a valid comment');
    } else if (this.comment && this.comment.split(' ').length > 120) {
      return this.alertService.error('Comment too long!');
    } else if (this.userData.comments.length > 2) {
      return this.alertService.error('You already comment on this Attendance!');
    } else if (!this.mailData.token) {
      return this.alertService.error('Unable to process this request!');
    } else {
      this.loader = true;
      this.mailData.comment = this.comment;
      this.userService.saveMailCommenting(this.mailData).subscribe(() => {
        this.loader = false;
        this.alertService.success('Comment saved successful, thank you!');
        setTimeout(() => {
          this.navigateService.navigateUrl('/');
        }, 1000);
      }, error => {
        this.alertService.error('Unable to send comment, please try again.');
        this.loader = false;
      });
    }
}
}
