import {AfterViewInit, Component, ElementRef, OnChanges, OnInit} from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import swal from 'sweetalert2';
declare const $: any;
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class MyAttendanceComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(private userService: UserService, private notifyService: NotificationService,
              private elementRef: ElementRef) { }
  public timeList: any[] = [];
  public comments: any[] = [];

  public loadingLogs: boolean;
  public totalCharge: number;
  public currentLog_ = null;
  public totalApprovedCharge: number;
  public showDays: number;
  public loadingLogs2: boolean;
  public loadingLogs3: boolean;
  public dailyFilter: boolean;
  public monthFilter: boolean;
  public isFewLogs: boolean;
  public filterParams: any;
  public filterMode: string;
  public user: any;
  public loadingFirstLogs: boolean;
  public availableMonths: any[] = [];
  public attendances: any[] = [];
  public cardMessage: string;

  public commentFetched: boolean;
  public message: string;
  public currentLog: string;


  ngOnInit() {
    this.commentFetched = false;
    this.user = jwt_decode(sessionStorage.getItem('access'));
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
    this.loadingLogs = true;
    this.loadingLogs2 = false;
    this.dailyFilter = false;
    this.monthFilter = false;
    this.loadingLogs = true;
    this.loadingFirstLogs = false;
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    this.getGeneratedAttendanceByStaff({year: year, month: month, user_id: this.user._id});
  }
  ngOnChanges() {

  }
  ngAfterViewInit() {
    // $('#datetimepicker1').datetimepicker();

  }
  public updateField(id) {
    setTimeout(() => {
      const val = $('#' + id).val();
      this.filterParams[id] = val;
    }, 1000);
  }
  public activateFirstDataTable() {
    $(('#attendance_log') as any).DataTable({
      'pagingType': 'full_numbers',
      'lengthMenu': [
        [30, 50, 100, 200, -1],
        [30, 50, 100, 200, 'All']
      ],
      destroy: true,
      responsive: true,
      'dom': 'lfrBtip',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search Logs',
      }
    });
  }
  public updateFilterView() {
    this.monthFilter = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
    this.filterMode = $('#selectFilter').val();
  }
  fetchAttendance() {
    const month = $('#getMonth').val();
    this.getGeneratedAttendanceByStaff({year: this.filterMode, month: month, user_id: this.user._id});
  }
  public getGeneratedAttendanceByStaff(data) {
    this.loadingFirstLogs = true;
    this.cardMessage = `Attendance report for ${data.month}, ${data.year}.`;
    this.userService.dailyAttendanceByStaff(data).subscribe((response: IResponse) => {
        this.attendances = response.data;
        this.totalCharge = 0;
        this.totalApprovedCharge = 0;
        this.attendances.forEach(attend => {
          this.totalCharge += parseFloat(attend.latenessCharge);
          this.totalApprovedCharge += parseFloat(attend.approvedCharge);
        });
        this.loadingFirstLogs = false;
       /* if (this.attendances.length > 0) {
          setTimeout(() => {
            this.activateFirstDataTable();
          }, 200);
        }*/
      },
      error => {
        this.loadingFirstLogs = false;
        this.notifyService.error('No attendance found for this month', error);
      });
  }





  public openCommentBox(log) {
    this.comments = null;
    this.currentLog = log._id;
    this.currentLog_ = log;
    if (!this.currentLog_.is_comment_enabled) {
      this.notifyService.error('Commenting have been disabled for this log!');
    }
    this.getComments(log._id, (has_comment) => {
      if (this.currentLog_.is_comment_enabled || has_comment) {
        this.commentFetched = true;
        setTimeout(() => {
          $('#leaveComment').modal({show: true, backdrop: 'static', keyboard: false });
          this.scrollIntoView();
        }, 10);
      } else {      }
    });
  }
  public getComments(id, cb = null) {
    this.userService.getLogComment(id).subscribe((response: IResponse) => {
        this.comments = response.data;
        const has_comment = this.comments.length > 0;
        (cb) ? cb(has_comment) : cb(false);
      },
      () => {
      this.notifyService.error('Unable to fetch comments');
      cb(false);
      });
  }
  public submitComment() {
    if (!this.message) {return;
    } else if (this.message && this.message.split(' ').length > 120) {
      return this.notifyService.info('Comment can not be more than 120 words!'); } else {
      this.userService.createComment({
        log_id: this.currentLog, comment: this.message,
        staff_id: this.user.staff_id,
        user: this.user._id}).subscribe((response: IResponse) => {
          this.getComments(this.currentLog, () => {
            this.message = null;
          });
        },
        (error) => {
          this.notifyService.error(error.error.msg || 'Unable to save comment');
        });
    }

  }
  public scrollIntoView() {
    setTimeout(() => {
      // $.scrollTo( $('.commentSession'), 500);
      this.elementRef.nativeElement.querySelector('.commentSession').scrollIntoView({behavior: 'smooth'});
      this.elementRef.nativeElement.querySelector('.commentSession').scrollTop = 0;
    }, 200);
  }
}
