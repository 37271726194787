import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import {
  isSameMonth,
  isSameDay
} from 'date-fns';
declare const $: any;

import {Observable, Subject} from 'rxjs';
import {NotificationService} from '../../services/notificationServices/notification.service';
import {UserService} from '../../services/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import * as moment from 'moment';
import {EventsService} from "../../services/eventServices/event.service";

interface Holiday {
  _id: string;
  title: string;
  date: string;
  process_date: string;
}

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  loaders = {loading: false, loading_: false};
  holiday = {
    title: null,
    date: null,
    isHoliday: false,
    process_date: null
  };
  view: CalendarView = CalendarView.Month;
  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();

  events$: Observable<any>;
  // events$: Observable<Array<CalendarEvent<{ film: Film }>>>;
  activeDayIsOpen = false;
  events: CalendarEvent[] = [];
  constructor(private http: HttpClient, private alertService: NotificationService,
              private userService: UserService, private eventService: EventsService ) {}

  ngOnInit(): void {
    this.fetchEvents();
    this.eventService.on('DONE', () => {
      this.fetchEvents();
      // this.ngOnInit();
    });
  }

  fetchEvents(): void {
    this.events$ = this.http
      .get('https://api.biochoice.ng/api/v1/holiday/?origin_selector_data_set=op0j5t-0oiojnhh-cds345')
      .pipe(
        map(({ data }: { data: Holiday[] }) => {
          return data.map((holiday: Holiday) => {
            return {
              title: holiday.title,
              start: new Date(
                holiday.date + getTimezoneOffsetString(this.viewDate)
              ),
              color: 'red',
              allDay: true,
              meta: {
                holiday
              }
            };
          });
        })
      );
  }
  dayClicked({
               date,
               events
             }: {
    date: Date;
    events: any;
  }): void {
    if (!events.length) {this.holiday = {
      title: null,
      isHoliday: false,
      date: moment(date).format('YYYY-MM-DD'),
      process_date: date
    };
      setTimeout(() => {
        $('#setHolidayModal').modal({show: true});
      }, 40);
    }
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }
  closeThisModal() {
    $('#setHolidayModal').modal('hide');
    setTimeout(() => {
      $('body').addClass('modal-open');
    }, 1000);
  }
  public createHoliday() {
    this.loaders.loading_ = true;
    if (!this.holiday.title) {
      this.loaders.loading = false;
      return this.alertService.error('Holiday title is required');
    } else {
      this.userService.createHoliday(this.holiday).subscribe((res: IResponse) => {
        this.loaders.loading_ = false;
        this.eventService.broadcast('DONE');
        this.holiday = {
          title: null,
          date: null,
          isHoliday: false,
          process_date: null
        };
        this.closeThisModal();
        this.refresh.next();
      }, error => {
        this.loaders.loading_ = false;
        this.eventService.broadcast('DONE');
        this.alertService.error('Unable to create holiday');
      });
    }
  }
  eventClicked(event: CalendarEvent<{ holiday: Holiday }>): void {
    (<any>Swal).fire({
      title: 'Do you want to delete?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then((result) => {
      if (result.value) {
        this.userService.deleteHoliday(event.meta.holiday._id).subscribe((res: IResponse) => {
          this.fetchEvents();
        }, error => {
          this.alertService.error('Unable to delete holiday!');
        });
      }
    });

  }
}
