import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import { environment as ENV } from '../../../environments/environment';
import swal from 'sweetalert2';
declare const $: any;
@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit, AfterViewInit {
public usersList: any[] = [];
public showStaffForm: boolean;
public updateProcess: boolean;
public loadPermit = false;
public userChangePicture: boolean;
public creating: boolean;
public loadingUsers: boolean;
public userData: any;
public setLeaveUser = null;
public message: string;
public activeUser;
public devicesInCompany = [];
public userCurrentPermissions = [];
public leave = {leaveStart: null, leaveEnd: null, _id: null, on_leave: false};
  public selectedFile: FileList;
  public formData: any;
  public uploadTriggered: boolean;
  public loading: boolean;
  public allowCalendar: boolean;
// public EMAIL_VALIDATION = ENV.EMAIL_VALIDATION;
  constructor(private userService: UserService, public notifyService: NotificationService) {
    this.loading = false;
  }

  ngOnInit() {
    this.allowCalendar = false;
    this.loadingUsers = true;
    this.getUsers();
    this.getDeviceByDBId();
    this.uploadTriggered = false;
    this.updateProcess = false;
    this.userChangePicture = false;
    this.creating = false;
    this.showStaffForm = false;
    this.message = null;
    this.userData  = {
      permission: 'LEVEL_ONE',
      email: null,
      last_name: null,
      first_name: null,
      access_status: 'ALLOWED',
      role: 'USER',
      profile_picture: null,
      staff_id: null,
      address: null,
      city: null,
      nearest_bus_stop: null
    };
  }
  ngAfterViewInit() {
    // this.initMaterialWizard();

  }

  public getDeviceByDBId() {
    this.userService.getDevices().subscribe((res: IResponse) => {
      this.devicesInCompany = res.data;
    }, error => {
      this.notifyService.error(error.error.msg || 'Unable to get company devices');
    });
  }
  public getUserInitialPermissions(userId, cb) {
    this.userService.getUserDevicePermission(userId).subscribe((res: IResponse) => {
      this.userCurrentPermissions = res.data;
      this.devicesInCompany.forEach((device) => {
        device.permit = false;
        const foundPermit = this.userCurrentPermissions.find((currentPermit) => currentPermit.device_id === device._id) || null;
        if (foundPermit) {
          device.permit = true;
        }
      });
      cb();
    }, error => {
      this.notifyService.error(error.error.msg || 'Unable to get device permission');
    });
  }
  public resetUserData() {
    this.userData  = {
      permission: 'LEVEL_ONE',
      email: null,
      last_name: null,
      first_name: null,
      access_status: 'ALLOWED',
      role: 'USER',
      profile_picture: null,
      staff_id: null,
      address: null,
      city: null,
      nearest_bus_stop: null
    };
  }
public activateDataTable() {
  $(('#datatables') as any).DataTable({
    'pagingType': 'full_numbers',
    'lengthMenu': [
      [30, 50, 100, 200, -1],
      [30, 50, 100, 200, 'All']
    ],
    destroy: true,
    responsive: true,
    'dom': 'lfrBtip',

    'buttons': [
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }, {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }, {
        extend: 'print',
        exportOptions: {
          columns: [ 0, 1, 2, 3, 4 ]
        }
      }
    ],
    language: {
      search: '_INPUT_',
      searchPlaceholder: 'Search Users',
    }
  });
  // const table = $('#datatable').DataTable();
}
public getUsers() {
  this.loadingUsers = true;
  this.usersList = [];
  this.userService.getUsers({filter: 'ALLOWED'}).subscribe((response: IResponse) => {
      this.usersList = response.data;
      console.log('users', this.usersList);
      this.loadingUsers = false;
      if (this.usersList.length > 0) {
        setTimeout(() => {
          this.activateDataTable();
        }, 100);
      }
    },
    error => {
      this.notifyService.error('No user found!', error);
      this.loadingUsers = false;
    });
}
  public initMaterialWizard() {
    // Code for the Validator
    const $validator = $('.card-wizard form').validate({
      rules: {
        firstname: {
          required: true,
          minlength: 3
        },
        lastname: {
          required: true,
          minlength: 3
        },
        email: {
          // required: true,
          minlength: 3,
          // pattern: this.EMAIL_VALIDATION
        },
        staff_id: {
          required: true,
          minlength: 2,
        }
      },

      highlight: (element) => {
        $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
      },
      success: (element) => {
        $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
      },
      errorPlacement: (error, element) => {
        $(element).append(error);
      }
    });

    // Wizard Initialization
    $('.card-wizard').bootstrapWizard({
      'tabClass': 'nav nav-pills',
      'nextSelector': '.btn-next',
      'previousSelector': '.btn-previous',

      onNext: (tab, navigation, index) => {
        const $valid = $('.card-wizard form').valid();
        if (!$valid) {
          $validator.focusInvalid();
          return false;
        }
      },

      onInit: function(tab, navigation, index) {
        // check number of tabs and fill the entire row
        const $total = navigation.find('li').length;
        const  $wizard = navigation.closest('.card-wizard');

        const $first_li = navigation.find('li:first-child a').html();
        const $moving_div = $('<div class="moving-tab">' + $first_li + '</div>');
        $('.card-wizard .wizard-navigation').append($moving_div);

        refreshAnimation($wizard, index);

        $('.moving-tab').css('transition', 'transform 0s');
      },

      onTabClick: function(tab, navigation, index) {
        const $valid = $('.card-wizard form').valid();

        if (!$valid) {
          return false;
        } else {
          return true;
        }
      },

      onTabShow: function(tab, navigation, index) {
        const $total = navigation.find('li').length;
        const $current = index + 1;

        const $wizard = navigation.closest('.card-wizard');

        // If it's the last tab then hide the last button and show the finish instead
        if ($current >= $total) {
          $($wizard).find('.btn-next').hide();
          $($wizard).find('.btn-finish').show();
        } else {
          $($wizard).find('.btn-next').show();
          $($wizard).find('.btn-finish').hide();
        }

        const button_text = navigation.find('li:nth-child(' + $current + ') a').html();

        setTimeout(function() {
          $('.moving-tab').text(button_text);
        }, 150);

        const checkbox = $('.footer-checkbox');

        if (!index) {
          $(checkbox).css({
            'opacity': '0',
            'visibility': 'hidden',
            'position': 'absolute'
          });
        } else {
          $(checkbox).css({
            'opacity': '1',
            'visibility': 'visible'
          });
        }

        refreshAnimation($wizard, index);
      }
    });


    // Prepare the preview for profile picture
    $('#wizard-picture').change(function() {
      readURL(this);
    });

    $('[data-toggle="wizard-radio"]').click(function() {
      const wizard = $(this).closest('.card-wizard');
      wizard.find('[data-toggle="wizard-radio"]').removeClass('active');
      $(this).addClass('active');
      $(wizard).find('[type="radio"]').removeAttr('checked');
      $(this).find('[type="radio"]').attr('checked', 'true');
    });

    $('[data-toggle="wizard-checkbox"]').click(function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).find('[type="checkbox"]').removeAttr('checked');
      } else {

        $('.choice').removeClass('active');
        $(this).addClass('active');
        $(this).find('[type="checkbox"]').attr('checked', 'true');
      }
    });

    $('.set-full-height').css('height', 'auto');

    // Function to show image before upload

    function readURL(input) {
      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = function(e) {
          $('#wizardPicturePreview').attr('src', e.target['result']).fadeIn('slow');
          // this.userData.profile_picture = e.target['result'];
        };
        reader.readAsDataURL(input.files[0]);
      }
    }

    $(window).resize(function() {
      $('.card-wizard').each(function() {
       const $wizard = $(this);

       const index = $wizard.bootstrapWizard('currentIndex');
        refreshAnimation($wizard, index);

        $('.moving-tab').css({
          'transition': 'transform 0s'
        });
      });
    });

    function refreshAnimation($wizard, index) {
     const $total = $wizard.find('.nav li').length;
      let $li_width = 100 / $total;

      const total_steps = $wizard.find('.nav li').length;
      let move_distance = $wizard.width() / total_steps;
      let index_temp = index;
      // let vertical_level = 0;

      const mobile_device = $(document).width() < 600 && $total > 3;

      if (mobile_device) {
         move_distance = $wizard.width() / 2;
         index_temp = index % 2;
        $li_width = 50;
      }

      $wizard.find('.nav li').css('width', $li_width + '%');

      const step_width = move_distance;
      move_distance = move_distance * index_temp;

      const $current = index + 1;

      if ($current === 1 || (mobile_device === true && (index % 2 === 0))) {
        move_distance -= 8;
      } else if ($current === total_steps || (mobile_device === true && (index % 2 === 1))) {
        move_distance += 8;
      }
      let vertical_level;
      if (mobile_device) {
         vertical_level = index / 2;
        vertical_level = vertical_level * 38;
      }

      $wizard.find('.moving-tab').css('width', step_width);
      $('.moving-tab').css({
        'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

      });
    }
  }
public createStaffTrigger(staff) {
    this.showStaffForm = !this.showStaffForm;
    if (!this.showStaffForm) {
      $('#datatables').empty();
      this.getUsers();
    }
  (staff) ? this.userData = staff : this.resetUserData();
  if (this.showStaffForm) {
    setTimeout(() => {
    this.initMaterialWizard();
  }, 40); }
  }
  public processFileForUpload(e) {
    this.formData = new FormData();
    this.formData.append('file', e.target.files[0]);
  }
  public createNewStaff() {
    this.creating = true;
    if ($('#wizardPicturePreview').attr('src').includes(';base64,')) {
     this.userData.profile_picture = $('#wizardPicturePreview').attr('src');
   } else {
     const a  = '';
   }
    if (this.userData.staff_id && this.userData.first_name && this.userData.last_name) {
      this.userService.createOrUpdateStaff(this.userData, this.updateProcess).subscribe((response: IResponse) => {
        this.creating = false;
        this.showStaffForm = false;
        this.notifyService.success(response.msg);
        this.resetUserData();
        $('#wizardPicturePreview').attr('src', '../../assets/img/default-avatar.png');
        this.getUsers();
      }, error => {
        this.notifyService.error(error.error.msg || 'Error!, unable to create/update staff!');
        this.creating = false;
      });
    } else {
      this.notifyService.error('You have to enter staff id, firstname, lastname to proceed!');
      this.creating = false;
    }
  }
  public selectRole(role) {
    this.userData.role = role;
  }
  public updateStaff(staff) {
    this.createStaffTrigger(staff);
    this.updateProcess = true;
    setTimeout(() => {
      $(`#${staff.role}`).addClass('active');
    }, 50);
  }
  public deleteUser(user) {
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.userService.deleteStaff(user._id).subscribe((response: IResponse) => {
          this.notifyService.success(response.msg);
          this.getUsers();
        }, error => {
          this.notifyService.error('Unable to delete staff!');
        });
      }
    });
  }
  updateReceiveMail(user) {
    this.userService.createOrUpdateStaff({receive_mail: !user.receive_mail, _id: user._id}, true).subscribe((response: IResponse) => {
      this.notifyService.success('Mail status updated!');
      // this.getUsers();
    }, error => {
      this.notifyService.error('Unable to update!');
    });
  }
  sendLoginCredentialModal() {
    setTimeout(() => {
      $('#sendMail').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }
  setHolidayCalendar() {
    this.allowCalendar = true;
    setTimeout(() => {
      $('#setCalendarModal').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }
  public sendMailToUsers() {
    this.loading = true;
    this.userService.sendMail({message: this.message}).subscribe((response: IResponse) => {
     this.loading = false;
      this.notifyService.success('Mail Sent successfully!');
      this.message = null;
    }, error => {
      this.loading = false;
      this.notifyService.error('Unable to send mail!');
    });
  }
  public setLeaveModal(user) {
    this.setLeaveUser = user;
    this.leave.on_leave = this.setLeaveUser.on_leave;
    this.leave.leaveEnd = this.setLeaveUser.leaveEnd;
    this.leave.leaveStart = this.setLeaveUser.leaveStart;
    setTimeout(() => {
      (<any>$('.datepicker_class')).datetimepicker({
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        format: 'YYYY-MM-DD',
        minDate: new Date(), maxDate: new Date(2030, 1, 18)
      });
      $('#setLeave').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }
  public setPermissionModal(user) {
    this.activeUser = JSON.parse(JSON.stringify(user));
    this.loadPermit = true;
    this.getUserInitialPermissions(this.activeUser._id , () => {
      this.loadPermit = false;
    });
    setTimeout(() => {
      $('#permissionSetter').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }
  public setLeave() {
    this.loading = true;
    this.leave._id = this.setLeaveUser._id;
    this.leave.on_leave = true;
    this.userService.createOrUpdateStaff(this.leave, true).subscribe((res) => {
      $('#setLeave').modal('hide');
      this.loading = false;
      this.setLeaveUser = null;
      this.getUsers();
      this.leave = {leaveStart: null, leaveEnd: null, _id: null, on_leave: false};
    }, error => {
      this.setLeaveUser = null;
      this.loading = false;
      $('#setLeave').modal('hide');
      this.notifyService.error('Unable to set leave for this user at the moment!');
    });
  }
  public savePermissionsSet() {
    this.loading = true;
    this.userService.createUserDevicePermission(this.activeUser._id, {devicesPermission: this.devicesInCompany})
      .subscribe((res: IResponse) => {
      this.loading = false;
        this.loading = false;
        this.loadPermit = true;
        this.getUserInitialPermissions(this.activeUser._id , () => {
          this.loadPermit = false;
        });
      this.notifyService.success('User device permission created!');
    }, error => {
      this.notifyService.error(error.error.msg || 'Unable to create device permission for this user.');
    });
  }

  public closePermission() {
    this.activeUser = null;
  }
  public updateField(id) {
    setTimeout(() => {
      // console.log('uuuuuuuuuu ', val);
      this.leave[id] = $('#' + id).val();
    }, 1000);
  }
}
