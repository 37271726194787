import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import * as JWT_DECODE from 'jwt-decode';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../shared/services/userService/user.service';
import {NavigatorService} from '../../shared/services/navigatorService/navigator.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import {CacheService} from '../../shared/services/cacheService/cache.service';
import {IResponse} from '../../shared/interfaces/iresponse';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public credentials = {
    confirmPassword: null,
    password: null,
    userId: null,
  };
  year = 2019;
  public userDetails: any;
  loaders = {
    loading: false
  };
  constructor(private bootstrapNotify: NotificationService,
              private userService: UserService,
              private navigatorService: NavigatorService,
              private route: ActivatedRoute,
              private cacheService: CacheService) {
    const secretCode = this.route.snapshot.paramMap.get('codeSecret');
    if (secretCode.toString().charAt(0) !== 'Q') {
      this.navigatorService.navigateUrl('/');
    }
  }
  ngOnInit(): void {
    const date = new Date();
    this.year = date.getFullYear();
    this.credentials.userId = this.route.snapshot.paramMap.get('userId');
    this.userDetails = null;
    this.checkFullPageBackgroundImage();
    setTimeout(function() {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);
  }
  public checkFullPageBackgroundImage() {
    const page = $('.full-page');
    const image_src = page.data('image');
    if (image_src !== undefined) {
      const image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>';
      page.append(image_container);
    }
  }

  public resetPassword() {
    this.loaders.loading = true;
    this.cacheService.deleteSession(ENV.TOKEN);
    this.cacheService.deleteStorage(ENV.TOKEN);
    if (!this.credentials.confirmPassword || !this.credentials.password) {
      this.bootstrapNotify.info('Provide New Password!');
    } else {
      this.userService.resetPassword(this.credentials).subscribe((response: IResponse) => {
        this.loaders.loading = false;
        this.bootstrapNotify.success(response['msg'] || 'Please proceed to login');
        this.navigatorService.navigateUrl('/');
      }, error => {
        this.bootstrapNotify.error(error.error.msg || 'Unable to reset password', 'right');
        this.loaders.loading = false;
        });
    }
  }
}
