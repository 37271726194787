/**
 *
 * Created By Arokoyu Olalekan Ojo <arokoyuolalekan@gmail.com> @ 8/9/2017
 *
 */
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DecryptDataService} from '../encryption/encrypt-data.service';
import {isNullOrUndefined} from 'util';
import {CacheService} from '../cacheService/cache.service';
import {HttpClient} from '@angular/common/http';
import { environment as ENV } from '../../../../environments/environment';
import {UserService} from '../userService/user.service';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';

@Injectable()
export class AuthService {
  public redirectUrl = '/member-routes/member/feeds'; // Here is where the requested url is stored
  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private decryptData: DecryptDataService,
    private activatedRoute: ActivatedRoute, private cache: CacheService) {}

  public loggedAdminIn(): boolean {
    return  this.checkAdminLogin();
  }

  public redirect(): void {
    this.router.navigate([ this.redirectUrl ]); // use the stored url here
  }
  public redirect_login(): void {
    this.router.navigate([ '/login' ]); // use this url
  }
  public redirect_signup(email): void {
    this.router.navigate([ '/home/' + email ]); // use this url
  }
  public redirector(url: string): void {
    this.router.navigate([ url ]); // use this url
  }
 public checkBrowserSession () {
    if (isNullOrUndefined(this.cache.getSession('Kolanut-Application-running'))) {
     // Since a user can decide to use the app on two tabs, session will only appear on the particular tab log in
      /* localStorage.removeItem('userAccessToken');
      localStorage.removeItem('token');
      localStorage.removeItem('token_organisation');
      localStorage.removeItem('organisationLoggedInEntity');*/
    } else {}
 }
  /**
   * Ensure user / member sign in
   * @returns {boolean}
   */
  public checkAdminLogin(): boolean {
    const userObject: string = this.cache.getSession('access');
    if (userObject === undefined || userObject === '' || userObject === null || isNullOrUndefined(userObject) ) {
      this.cache.deleteSession('access');
      return false;
    } else {
      // return true;
     const userDataDecrypted =  jwt_decode(this.cache.getSession('access'));
     const actionKey = userDataDecrypted.accessKey;
     if (actionKey !== ENV.ACTION_KEY) {
       this.cache.deleteSession('access');
       return false;
     } else if (userDataDecrypted.access_status !== 'ALLOWED') {
       this.cache.deleteSession('access');
       return false;
     }
      return true;
    }
  }


  public logOut() {
    this.cache.clearSession();
    this.cache.clearStorage();
    this.userService.logOut();
    return true;
  }
  public logUserOut() {
    this.userService.logOut().subscribe(() => {
      this.logOut();
    });
    this.logOut();
    this.router.navigate(['/']);
  }
}
