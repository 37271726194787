import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
import { environment as ENV } from '../../../environments/environment';
import {CSVService} from "../../shared/services/csvServices/pdf.service";
import {DecryptDataService} from "../../shared/services/encryption/encrypt-data.service";
declare const $: any;

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
public usersList = [];
public availableYears = [];
public guestList = [];
public availableMonths = [];
public logCount = 0;
public filterMode: number;
public usersCount = 0;
public selectedFile: FileList;
public formData: any;
public downloading: boolean;
public monthFilter = false;


public guestCount = 0;
  constructor(private userService: UserService,
              private decryptService: DecryptDataService,
              private notifyService: NotificationService,
              private csvGen: CSVService) {
    this.availableYears = [];
    const date = new Date();
    const year = date.getFullYear();
    for (let i = year - 3; i <= year; i++ ) {
      this.availableYears.push(i);
    }
  }

  ngOnInit() {
  this.getUsersCount();
  this.getGuestsCount();
  this.getLogsCount();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
  this.downloading  = false;
  }
  public getUsersCount() {
    this.userService.getUserCount().subscribe((response: IResponse) => {
        this.usersCount = response.data;
        },
      () => {      });
  }
  public getLogsCount() {
    this.userService.getLogCount().subscribe((response: IResponse) => {
        this.logCount = response.data;
      },
      () => {      });
  }
  public getGuestsCount() {
    this.userService.getGuestCount().subscribe((response: IResponse) => {
        this.guestCount = response.data;
      },
      () => {});
  }
  public importFiles(e) {
    this.fileUpload(e);
  }
  public importFileSelector(id) {
    $('#' + id).trigger('click');
  }
  public fileUpload(e) {
    this.selectedFile = e.target.files;
    if (this.selectedFile.length < 1) { return false; }
    // let validExt;
    const file = this.selectedFile.item(0);
    let validExts;
    // '.xlsx', '.xls',
    validExts = ['.csv'];
    let fileExt;
    fileExt = file.name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      this.notifyService.error(`Invalid file selected, valid files are of " ${validExts.toString()}  types.`);
      return false;
    } else {
      this.notifyService.info('Importing in progress in background.');
      this.formData = new FormData();
      this.formData.append('file', e.target.files[0]);
      this.proceedToImport(this.formData);
      }
  }
  public proceedToImport(file) {
    const request = new XMLHttpRequest();
    const token = sessionStorage.getItem('access');
    let ENDPOINT = '';
    if (sessionStorage.getItem('system-target')) {
      const systemTarget = this.decryptService.decryptString('system-target');
      ENDPOINT = ENV.API_URL + '/' + ENV.API_VERSION + '/users/import?' + systemTarget;
    }
    request.open('POST',
      `${ENDPOINT}`, true);
    request.onload = (response) => {
      if (request.status === 200) {
        const response_ = JSON.parse(request.response);
        if (response_.code === 200 && response_.status === 'SUCCESS') {
          this.notifyService.success(response_.msg);
          this.getUsersCount();
          this.getGuestsCount();
          this.getLogsCount();
        } else {
          this.notifyService.error(response_.msg);
          this.getUsersCount();
          this.getGuestsCount();
          this.getLogsCount();
          return false;
        }
      } else {
        this.notifyService.error('Error while trying to import staffs.');
      }
    };
    request.setRequestHeader('Authorization', token);
    request.send(file);
  }
  public downloadFormat(url, id) {
    this.downloading = true;
    const hiddenIFrameID = id;
    let iframe;
    iframe = document.getElementById(hiddenIFrameID);
    if (iframe === null) {
      iframe = document.createElement('iframe');
      iframe.id = hiddenIFrameID;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }
    iframe.src = url;
    setTimeout(() => {
      this.downloading = false;
    }, 2000);
  }
  public downloadCSV(target) {
    this.notifyService.info('Downloading in progress...');
    this.userService.exportToCSV(target).subscribe((response: IResponse) => {
      this.csvGen.downloadCsvFormatforStaffs(response.data, () => {
        console.log('Users In this company ', response.data);
        this.notifyService.success('Your CSV have been downloaded.');
      });
    }, error => {
      this.notifyService.error('Unable to export to CSV');
    });
  }


  public downloadSyncData() {
    this.notifyService.info('Downloading in progress...');
    this.userService.exportToCSVSyncData().subscribe((response: IResponse) => {
      this.csvGen.downloadCsvFormatforStaffs(response.data, () => {
        console.log('Synced Data ', response.data);
        this.notifyService.success('Your CSV have been downloaded.');
      });
    }, error => {
      this.notifyService.error('Unable to export to CSV');
    });
  }



  public updateFilterView() {
    this.monthFilter = false;
    this.filterMode = $('#selectFilter').val();
    this.getMyGenerators(this.filterMode);
  }

  public getMyGenerators(year) {
    const months = ['January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    this.userService.getGenerator({year}).subscribe((response: IResponse) => {
      this.monthFilter = true;
      const availableMonths = response.data;
      const date = new Date();

      availableMonths.forEach(record => {
        const index = parseInt(record.month, 10) - 1;
        months.splice(index, 1);
      });
      this.availableMonths = months;
      setTimeout(() => {
        $('.selectpicker').selectpicker();
      }, 20);
    }, error => {});
  }
  generateAttendance() {
    const months = ['January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date();
    const monthSelected = $('#getMonth').val();
    let selMonth = months.indexOf(monthSelected);
    const month = date.getMonth();
    const day = date.getDate();
    if (selMonth > month) {
      this.notifyService.error('You cannot generate attendance for future month!');
      return;
    }  else if (day <= 22 && selMonth === month ) {
      this.notifyService.error('This month is not due for generating report, please try again after 22nd of the month!');
      return;
    } else {
      selMonth++;
      this.notifyService.info('Generating in progress!!');
      this.userService.generateAttendance({year: this.filterMode, month: selMonth}).subscribe((res: IResponse) => {
        this.notifyService.success(res.msg || 'Attendance generated successful for ' + monthSelected);
        this.monthFilter = false;
      }, (error) => {
        this.notifyService.error('Unable to generate report for ' +  monthSelected );
      });
    }
  }
}
