import { Component, OnInit } from '@angular/core';
import {IResponse} from '../../shared/interfaces/iresponse';
import {UserService} from '../../shared/services/userService/user.service';
import {NotificationService} from '../../shared/services/notificationServices/notification.service';
declare const $: any;
@Component({
  selector: 'app-manage-companies',
  templateUrl: './manage-companies.component.html',
  styleUrls: ['./manage-companies.component.css']
})
export class ManageCompaniesComponent implements OnInit {
  companyList: any[];
  deviceList: any[];
  showCompanyForm: boolean;
  savingCompany: boolean;
  loadingCompanies: boolean;
  companyData: any;
  activeCompany = null;
  popup = {
    title: 'Create company',
    btn: 'SUBMIT'
  };
  constructor(private userService: UserService, private notifyService: NotificationService) {
    this.deviceList = [];
    this.getDevices();
  }

  ngOnInit() {
    this.savingCompany = false;
    this.loadingCompanies = true;
    this.companyList = [];
    this.companyData = {
      company_name: null,
      company_email: null,
      company_code: null
    };
    this.showCompanyForm = false;

  }
  public getCompanies() {
    this.userService.userListCompanies().subscribe((response: IResponse) => {
        this.companyList = response.data;
        this.loadingCompanies = false;
        if (this.companyList.length > 0) {
          this.companyList.forEach((company) => {
            company.device = 0;
            this.deviceList.forEach((device) => {
              if (device.dbId._id === company._id) {
                company.device += 1;
              }
            });
          });
        }
      },
      error => {
        this.loadingCompanies = false;
        this.notifyService.error('No Company found!', error);
      });
  }
  public createCompanyTrigger() {
    setTimeout(() => {
      this.popup = {
        title: 'Create company',
        btn: 'SUBMIT'
      };
      $('#createCompany').modal({show: true, keyboard: false, backdrop: 'static'});
    }, 40);
  }
public triggerEdit(company) {
    this.companyData = this.activeCompany = JSON.parse(JSON.stringify(company));
    this.popup = {
      title: 'Update company',
      btn: 'SAVE'
    };
    $('#createCompany').modal({show: true, keyboard: false, backdrop: 'static'});
}
public closeModal() {
  this.savingCompany = false;
  $('#createCompany').modal('hide');
    $('#createCompany').hide();
  this.companyData = {
    company_name: null,
    company_email: null,
    company_code: null
  };
  this.activeCompany = null;
}
public validateAndCreate() {
    this.savingCompany = true;
    if (this.activeCompany) {
      if (!this.companyData.company_name || !this.companyData.company_code || !this.companyData.company_email) {
        return this.notifyService.error('Fill the form before saving');
      }
      this.userService.updateCompany(this.companyData, this.activeCompany._id).subscribe((response: IResponse) => {
        this.notifyService.success(response.msg);
        this.getCompanies();
        this.closeModal();
      }, error => {
        this.savingCompany = false;
        this.notifyService.error(error.msg || 'Cannot update company');
      });
    } else {
      for ( const [key, value] of Object.entries(this.companyData)) {
        if (!this.companyData[key]) {
          this.savingCompany = false;
          this.notifyService.error(`${key} cannot be empty`);
          return false;
        }
      }
      this.userService.createCompany(this.companyData).subscribe((response: IResponse) => {
        this.notifyService.success(response.msg);
        this.getCompanies();
        this.closeModal();
      }, error => {
        this.savingCompany = false;
        this.notifyService.error(error.msg || 'Cannot create company');
      });
    }
}

  public getDevices() {
    this.userService.adminListDevices().subscribe((response: IResponse) => {
        this.deviceList = response.data;
        this.getCompanies();
      },
      error => {
        this.getCompanies();
        this.notifyService.error('No Device found!', error);
      });
  }
}
